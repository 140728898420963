import qs from "qs";
import { origins, splitIOApiKey } from "./constants";

let windowQuery: any = {};
try {
  windowQuery = qs.parse(window.location.search.substring(1));
} catch (err) {
  // eslint-disable-next-line no-console
  console.error("error parsing window query string", err);
}

export const carbonOffsetFeature = "carbon_offset_quote_flow";
export const mktgCardsPersonalizationFeature =
  "app-15692-mktg-cards-personalization"; // still at 90/10% split
export const quoteShareTaxShippingFeature = "app-16912-quote-pdf-tax-shipping";
export const quoteWithoutCADFeature = "app-19204-quote-without-cad-panel";
export const bulkSharingFeature = "APP-48921-bulk-sharing";

export function getSplitIOConfig(splitKey: string): SplitIO.IBrowserSettings {
  return {
    core: {
      authorizationKey: windowQuery.mockSplits ? "localhost" : splitIOApiKey,
      key: splitKey,
    },
    features: {
      ...(windowQuery.mockSplitFeatures || {}),
      [`psp_banner_w_icon`]: {
        treatment: "on",
        config: JSON.stringify({
          pspRank: 1,
          pspSecondaryBannerProps: {
            title: "Lorem ipsum dolor sit amet",
            bodyText:
              "Facilisi morbi tempus iaculis urna id volutpat. Feugiat sed lectus vestibulum mattis. Pretium viverra suspendisse potenti nullam.",
            icon:
              "https://d2njopo061ehj4.cloudfront.net/images/Process-Icons/injection-molding.svg",
            learnMoreLink: {
              text: "Learn More",
              link: "https://www.xometry.com/injection-molding-service",
            },
            ctaButton: {
              text: "Call To Action",
              link: `${origins.webapp}/`,
            },
          },
        }),
      },
      [`psp_banner_wo_icon`]: {
        treatment: "on",
        config: JSON.stringify({
          pspRank: 2,
          pspSecondaryBannerProps: {
            title: "Lorem ipsum dolor sit amet",
            bodyText:
              "Facilisi morbi tempus iaculis urna id volutpat. Feugiat sed lectus vestibulum mattis. Pretium viverra suspendisse potenti nullam.",
            icon: null,
            learnMoreLink: null,
            ctaButton: {
              text: "Call To Action",
              link: `#foo`,
            },
          },
        }),
      },
      [`app-9877-2d-manufacturing-services-ad`]: "on",
      [`app-19204-quote-without-cad-panel`]: "on",
    },
  };
}
